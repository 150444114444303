import React, { setGlobal, useEffect } from 'reactn';
import SSRProvider from 'react-bootstrap/SSRProvider';
import TagManager from 'react-gtm-module';
import Script from 'next/script';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/scss/style.scss';

const BoutirApp = ({ Component, pageProps, router }) => {
	useEffect(() => {
		import('react-facebook-pixel')
		  .then((x) => x.default)
		  .then((ReactPixel) => {
			ReactPixel.init('788102717976610')
			ReactPixel.init('620410513204800')
			ReactPixel.pageView()
	
			router.events.on('routeChangeComplete', () => {
			  ReactPixel.pageView()
			})
		  })
	}, [router.events])

	useEffect(() => {
		TagManager.initialize({ gtmId: process.env.gtmId })
	}, []);
	
	if(router.query){
		const { query } = router;
		const { region, lang } = query;
		const urlCountry = (region !== undefined && region ? region : 'HK');
		const urlLang = (lang !== undefined && lang ? lang : 'en');
		const component = Component.name.toLowerCase();

		setGlobal({
			serverReq: (typeof window === 'undefined' ? true : false),
			linkstate:{
				country: urlCountry,
				lang: urlLang,
				component: component
			}
		});
	}

	return (
		<SSRProvider>
		<>
			<Component {...pageProps} />
			<Script
				type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src="https://js-na1.hs-scripts.com/20169539.js"
				strategy="lazyOnload"
			/>
		</>
		</SSRProvider>
	);
}

export default BoutirApp;